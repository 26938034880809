import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/src/components/error/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainLayout"] */ "/vercel/path0/src/components/layout/MainLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PWAProvider"] */ "/vercel/path0/src/components/providers/PWAProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GifScript"] */ "/vercel/path0/src/components/scripts/GifScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ServiceWorkerRegistration"] */ "/vercel/path0/src/components/scripts/ServiceWorkerRegistration.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/src/components/theme-provider.tsx");
