'use client';

import { useState, useEffect } from 'react';
import { Sun, Moon } from 'lucide-react';
import Link from 'next/link';
import { useTheme } from 'next-themes'

interface MainLayoutProps {
  children: React.ReactNode;
}

export function MainLayout({ children }: MainLayoutProps) {
  const { theme, setTheme } = useTheme()

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  return (
    <div className="min-h-screen bg-background transition-colors duration-200">
      {/* Header */}
      <header className="border-b border-gray-200 dark:border-gray-800">
        <div className="max-w-6xl mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-8">
              <Link 
                href="/" 
                className="text-xl font-bold text-foreground hover:opacity-80 transition-opacity"
              >
                Slack Emoji Creator
              </Link>
              <p className="text-sm text-gray-500 dark:text-gray-400 hidden sm:block">
                Create your custom Slack emoji in 3 simple steps
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={toggleTheme}
                className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
              >
                {theme === 'light' ? (
                  <Moon className="w-5 h-5 text-gray-600" />
                ) : (
                  <Sun className="w-5 h-5 text-gray-300" />
                )}
              </button>
              <Link 
                href="/about"
                className="text-sm text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hidden sm:block transition-colors"
              >
                About
              </Link>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-6xl mx-auto px-4 py-8">
        {children}
      </main>

      {/* Footer */}
      <footer className="border-t border-gray-200 dark:border-gray-800 mt-20">
        <div className="max-w-6xl mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* SEO Text */}
            <div>
              <h3 className="font-semibold mb-2 text-foreground">Create Custom Slack Emojis</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Transform your images into perfect Slack emojis in seconds. 
                Add animations, remove backgrounds, and customize your workspace 
                with unique expressions that match your team&apos;s personality.
              </p>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="font-semibold mb-2 text-foreground">Quick Links</h3>
              <ul className="text-sm space-y-2">
                <li>
                  <Link 
                    href="/disclaimer" 
                    className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors"
                  >
                    Privacy & Disclaimer
                  </Link>
                </li>
                <li>
                  <Link 
                    href="/about" 
                    className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors"
                  >
                    About Us
                  </Link>
                </li>
              </ul>
            </div>

            {/* Privacy Notice */}
            <div>
              <h3 className="font-semibold mb-2 text-foreground">Your Privacy Matters</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                We never store your images. All processing happens in your browser, 
                and your data is immediately discarded after download.
              </p>
            </div>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-200 dark:border-gray-800 text-center text-sm text-gray-500 dark:text-gray-400">
            <p>
              Built with ❤️ by Slack enthusiasts. No affiliation with Slack Technologies.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
