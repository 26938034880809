'use client';

import Script from 'next/script';

export function GifScript() {
  return (
    <Script
      src="https://cdnjs.cloudflare.com/ajax/libs/gif.js/0.2.0/gif.js"
      strategy="beforeInteractive"
      crossOrigin="anonymous"
      onError={(e) => {
        console.error('Failed to load gif.js:', e);
      }}
    />
  );
} 